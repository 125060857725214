import {HttpClient, HttpContext, HttpHeaders} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {GfiMeta} from './model';
import {environment} from '../../environments/environment';
import {RESPONSE_CONFIG} from '../response.config';


@Injectable({
  providedIn: 'root'
})
export class MetadataService {

  #http = inject(HttpClient)
  baseUrl = environment.apiUrl

  createMeta(gfi_id: number, clb_numero_meta: string, gfm_val: string): Observable<GfiMeta> {
    const url = this.baseUrl + "/gael/document/meta/create"
    const body: any = {
      gfi_id: gfi_id,
      clb_numero_meta: clb_numero_meta,
      gfm_val: gfm_val
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.#http.post<GfiMeta>(url,body,{ headers, context: new HttpContext().set(RESPONSE_CONFIG, {
        successMessage: "Métadonnée créée avec succès",
        errorMessage: 'Erreur lors de la création de la métadonnée.',
        duration: 5000,
        type: 'error',
        showToast: true
      }) });
  }

  updateMeta(gfi_id: number, gfm_id: number, clb_numero_meta: string, gfm_val: string): Observable<GfiMeta> {
    const url = this.baseUrl + "/gael/document/meta/update"
    const body: any = {
      gfm_id: gfm_id,
      gfi_id: gfi_id,
      clb_numero_meta: clb_numero_meta.toString(),
      gfm_val: gfm_val
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.#http.put<GfiMeta>(url,body,{ headers , context: new HttpContext().set(RESPONSE_CONFIG, {
        successMessage: "Métadonnée mise à jour avec succès",
        errorMessage: 'Impossible de mettre à jour la metadonnée',
        duration: 5000,
        type: 'error',
        showToast: true
      })});
  }

  deleteMeta(gfm_id: number): Observable<any> {
    const url = this.baseUrl + `/gael/document/meta/${gfm_id}`
    return this.#http.delete<any>(url, {context: new HttpContext().set(RESPONSE_CONFIG, {
        successMessage: "Métadonnée supprimée avec succès",
        errorMessage: 'Une erreur est survenue lors de la suppression de la métadonnée. Veuillez réessayer plus tard.',
        duration: 5000,
        type: 'error',
        showToast: true
      })});
  }


}
