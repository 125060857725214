<!--
-->
<router-outlet></router-outlet>
<div class="fixed bottom-4 right-4 z-50">
  @for (toast of toastService.toasts$(); track toast.id) {
    <div
      class="mb-2 p-4 rounded-lg shadow-lg transition-all duration-300 ease-in-out"
      [ngClass]="{
            'bg-red-500 text-white': toast.type === 'error',
            'bg-yellow-500 text-white': toast.type === 'warning',
            'bg-blue-500 text-white': toast.type === 'info',
            'bg-green-500 text-white': toast.type === 'success'
          }"
    >
      {{ toast.message }}
    </div>
  }
</div>
