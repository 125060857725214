<div class="max-w-md mx-auto mt-8">
  <div class="flex justify-center">
    <div> Souhaitez-vous supprimer {{fileToDelete()?.gfi_type}} - {{fileToDelete()?.gfi_nom}} ? <br/>
      Cette action ne peut pas être annulée.</div>
  </div>
  <div class="mt-2 flex justify-center">
    <button (click)="confirmDelete()" class="bg-red-500 text-white px-2 py-1 rounded">Supprimer</button>
    </div>
  <div class="mb-8"></div>
</div>

