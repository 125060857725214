import { Routes } from '@angular/router';
import { GaelSearchComponent } from './gael/layouts/search/search.component';
import {UnauthorizedComponent} from './gael/layouts/unauthorized/unauthorized.component';
import {NotFoundComponent} from './gael/layouts/not-found/not-found.component';
import {MainComponent} from './gael/layouts/main/main.component';
import {canActivateAuthRole} from './guard/auth.guard';
import {LogoutComponent} from './gael/layouts/logout/logout.component';

export const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    title: 'Gael - Accueil',
    children: [
      {
        path: "gael",
        component: GaelSearchComponent,
        title: 'Gael',
        canActivate: [canActivateAuthRole]
      }
    ]
  },
  {
    path: "logout",
    component: LogoutComponent
  },
  {
    path: "unauthorized",
    component: UnauthorizedComponent
  },
  {
    path: "**",
    component: NotFoundComponent
  }
];
