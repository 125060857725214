import {ApplicationConfig, provideZoneChangeDetection} from '@angular/core';
import {provideRouter} from '@angular/router';
import {provideHttpClient, withInterceptors} from '@angular/common/http';
import {routes} from './app.routes';
import {provideAngularSvgIcon} from 'angular-svg-icon';
import {provideKeycloakAngular} from './keycloak.config';
import {includeBearerTokenInterceptor} from 'keycloak-angular';
import {responseInterceptor} from './interceptor/http-response.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideHttpClient(withInterceptors([includeBearerTokenInterceptor, responseInterceptor])),
    provideAngularSvgIcon(),
    provideKeycloakAngular()
  ]
};
