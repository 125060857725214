import { Component, AfterViewInit, ElementRef,CUSTOM_ELEMENTS_SCHEMA, OnInit} from '@angular/core';
import { AwsService } from './services/aws.service';
import { environment } from '../environments/environment';
import * as firebase from 'firebase/app'
import { Router } from '@angular/router'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit,OnInit {
  title = 'AEL-Gaelle';
  commit = environment.commit;
  build = environment.build;

  constructor(private elementRef: ElementRef,
    private awsService:AwsService,private router: Router){

  }
  ngAfterViewInit(){
    //this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#F3E5F5';
 }
 ngOnInit() {
  this.awsService.initAmplify();

 /* console.log("AppComponent: Checking if the user is already authenticated");
  this.userService.isAuthenticated(this);*/
}

isLoggedIn(message: string, isLoggedIn: boolean) {
  console.log(
    'AppComponent: the user is authenticated: ' + isLoggedIn + ', ' + message
  )
  if (isLoggedIn) {
    this.router.navigate(['/demandes'], { skipLocationChange: true })
  }
}
 
}
