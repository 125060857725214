import {Component, effect, inject, OnInit} from '@angular/core';
import {HeaderComponent} from "../header/header.component";
import {Router, RouterOutlet} from '@angular/router';
import {AuthService} from '../../../guard/auth.service';

@Component({
  selector: 'app-main',
  imports: [
    HeaderComponent,
    RouterOutlet
  ],
  templateUrl: './main.component.html',
  styleUrl: './main.component.css'
})
export class MainComponent implements OnInit{
  private authService = inject(AuthService);
  private router = inject(Router);

  constructor() {
    effect(() => {
      if (this.authService.isAuthenticated()) {
        this.router.navigate(['/gael']);
      } else {
        this.authService.login()
      }
    });
  }


  ngOnInit() {}
}
