import {Component, inject, signal} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {FileModalComponent} from '../file-modal/file-modal.component';
import {GaelService} from '../../gael.service';
import {GaelFichierWSDTO, GaelSearchResponse, Item} from '../../model';
import {NgIcon, provideIcons} from '@ng-icons/core';
import {
  heroChevronDoubleLeft,
  heroChevronDoubleRight,
  heroChevronDown,
  heroChevronLeft,
  heroChevronRight,
  heroDocument,
  heroDocumentPlus,
  heroPencilSquare
} from '@ng-icons/heroicons/outline';
import {SvgIconComponent} from 'angular-svg-icon';


export interface SearchValues {
  codeInsee?: string;
  codeContrat?: string;
  codeMarque?: string;
  nomCommune?: string;
  codeRegion?: string;
  codeInseeGN?: string;
  nomCommuneGN?: string;
  codeTraiteGN?: string;
  codeCommuneGN?: string;
  searchMode: string;
}

@Component({
  selector: 'gael-search',
  templateUrl: './search.component.html',
  styleUrl: './search.component.css',
  imports: [CommonModule, FormsModule, FileModalComponent, NgIcon, SvgIconComponent],
  providers: [provideIcons({heroPencilSquare, heroChevronDoubleLeft, heroChevronDoubleRight, heroChevronLeft, heroChevronRight, heroChevronDown, heroDocumentPlus, heroDocument})]
})
export class GaelSearchComponent {

  private gaelService = inject(GaelService)

  items = signal<Item[]>([]);
  total = 0;
  page = signal<number>(1);
  size = 50;
  pages = 0;
  pageInput = 1;
  isSearching = signal<boolean>(true)

  selectedItem = signal<Item | null>(null);
  //searchMode: string = 'SIRIUS';
  searchCommand: SearchValues = {searchMode: 'sirius'};



  ngOnInit() {

    this.searchData("sirius")
  }

  searchData(system_source?: string,
    code_insee?: string[], code_contrat?: string[],
    code_marque?: string[], code_traite_gn?: string[],
    code_exp_gn?: string[], code_com_gn?: string[],
    com_nom?: string[]) {
    this.isSearching.set(true);
    this.gaelService.searchDocuments(this.page(), this.size, system_source, code_insee,
                                      code_contrat, code_marque, code_traite_gn,
                                      code_exp_gn, code_com_gn, com_nom)
                                      .pipe()
                                      .subscribe(
                                        {
                                          next: (response: GaelSearchResponse) => {
                                            this.items.set(response.items);
                                            this.total = response.total;
                                            this.page.set(response.page);
                                            this.size = response.size;
                                            this.pages = response.pages;
                                            this.pageInput = this.page() + 1;
                                            this.initializeItems(this.items());
                                            this.isSearching.set(false);
                                          },
                                          error: (error: any) => {
                                            console.error('Erreur lors du chargement des données:', error);
                                          }
                                        }
                                      );
  }

  onSearchModeChange() {
    console.log('Search mode changed to:', this.searchCommand.searchMode);
  }

  onSearch() {

     let code_insee: string[] | undefined = undefined;
     let code_contrat: string[] | undefined = undefined;
     let code_marque: string[] | undefined = undefined;
     let code_traite_gn: string[] | undefined = undefined;
     let code_exp_gn: string[] | undefined = undefined;
     let code_com_gn: string[] | undefined = undefined;
     let com_nom: string[] | undefined = undefined;


    if (this.searchCommand.searchMode === 'sirius')
    {
        code_insee = this.searchCommand.codeInsee ? this.searchCommand.codeInsee.split(',').map(s => s.trim()) : undefined;
        code_contrat = this.searchCommand.codeContrat ? this.searchCommand.codeContrat.split(',').map(s => s.trim()) : undefined;
        code_marque = this.searchCommand.codeMarque ? this.searchCommand.codeMarque.split(',').map(s => s.trim()) : undefined;
        com_nom = this.searchCommand.nomCommune ? this.searchCommand.nomCommune.split(',').map(s => s.trim()) : undefined;
    }
    if (this.searchCommand.searchMode === 'gn')
    {
      code_insee = this.searchCommand.codeInseeGN ? this.searchCommand.codeInseeGN.split(',').map(s => s.trim()) : undefined;
      com_nom = this.searchCommand.codeCommuneGN ? this.searchCommand.codeCommuneGN.split(',').map(s => s.trim()) : undefined;
      code_exp_gn = this.searchCommand.codeRegion ? this.searchCommand.codeRegion.split(',').map(s => s.trim()) : undefined;
      code_traite_gn = this.searchCommand.codeTraiteGN ? this.searchCommand.codeTraiteGN.split(',').map(s => s.trim()) : undefined;
      code_com_gn = this.searchCommand.codeCommuneGN ? this.searchCommand.codeCommuneGN.split(',').map(s => s.trim()) : undefined;
    }

    this.searchData(this.searchCommand.searchMode, code_insee, code_contrat,
                    code_marque, code_traite_gn, code_exp_gn,
                    code_com_gn, com_nom);

  }

  changePage(page: number) {
    if (page >= 0 && page <= this.pages) {
      this.page.set(page);
      this.onSearch();
    }
  }


  toggleExpand(item: Item) {
    item.expanded = !item.expanded;
  }


  toggleSelection(item: Item) {
    item.selected = !item.selected;
    // Vous pouvez ajouter ici une logique pour sélectionner/désélectionner les enfants si nécessaire
  }

  initializeItems(items: Item[]) {
    items.forEach(item => {
      item.expanded = true;
      item.selected = false;
      if (item.enfants && item.enfants.length > 0) {
        this.initializeItems(item.enfants);
      }
    });
  }

  openModal(item: Item) {
        this.selectedItem.set(item);
  }

  openFile(fichier: GaelFichierWSDTO) {
    this.gaelService.viewFile(fichier)
  }

  resetItem() {
    this.selectedItem.set(null)
  }

}
