<!-- Toolbar 
<caaks-header></caaks-header>
-->
<mat-sidenav-container class="example-container" autosize>

    <div fxFlexAlign="center" class="example-sidenav-content">
        <google-signin-btn></google-signin-btn>
        <!-- Router -->
        <router-outlet></router-outlet>
    </div>
</mat-sidenav-container>








<!-- Footer -->