<div *ngIf="isSearching()" class="fixed inset-0 flex items-center justify-center bg-white bg-opacity-75 min-h-svh ">
  <div class="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
</div>
<div class="max-w-4xl mx-auto p-6 bg-gray-100 rounded-lg shadow-lg">
  <form (ngSubmit)="onSearch()" #userForm="ngForm" class="space-y-6">
    <div class="flex items-center space-x-4">
      <label class="text-sm font-medium text-gray-700">Système source :</label>
      <select
        name="searchMode"
        [(ngModel)]="searchCommand.searchMode"
        (change)="onSearchModeChange()"
        class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-2 border-gray-300 bg-gray-50 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
      >
        <option value="sirius">SIRIUS</option>
        <option value="gn">GN</option>
      </select>
    </div>

    <div *ngIf="searchCommand.searchMode === 'sirius'" class="grid grid-cols-1 gap-6 sm:grid-cols-2">
      <div>
        <label for="codeInsee" class="block text-sm font-medium text-gray-700">Code Insee</label>
        <input id="codeInsee" type="text" name="codeInsee" [(ngModel)]="searchCommand.codeInsee"
               class="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-2 border-gray-300 bg-gray-50 rounded-md">
      </div>
      <div>
        <label for="codeContrat" class="block text-sm font-medium text-gray-700">Code Contrat</label>
        <input id="codeContrat" type="text" name="codeContrat" [(ngModel)]="searchCommand.codeContrat"
               class="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-2 border-gray-300 bg-gray-50 rounded-md">
      </div>
      <div>
        <label for="codeMarque" class="block text-sm font-medium text-gray-700">Code Marque</label>
        <input id="codeMarque" type="text" name="codeMarque" [(ngModel)]="searchCommand.codeMarque"
               class="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-2 border-gray-300 bg-gray-50 rounded-md">
      </div>
      <div>
        <label for="nomCommune" class="block text-sm font-medium text-gray-700">Nom Commune</label>
        <input id="nomCommune" type="text" name="nomCommune" [(ngModel)]="searchCommand.nomCommune"
               class="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-2 border-gray-300 bg-gray-50 rounded-md">
      </div>
    </div>

    <div *ngIf="searchCommand.searchMode === 'gn'" class="grid grid-cols-1 gap-6 sm:grid-cols-2">
      <div>
        <label for="codeRegion" class="block text-sm font-medium text-gray-700">Code Région</label>
        <input id="codeRegion" type="text" name="codeRegion" [(ngModel)]="searchCommand.codeRegion"
               class="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-2 border-gray-300 bg-gray-50 rounded-md">
      </div>
      <div>
        <label for="codeInseeGN" class="block text-sm font-medium text-gray-700">Code INSEE</label>
        <input id="codeInseeGN" type="text" name="codeInseeGN" [(ngModel)]="searchCommand.codeInseeGN"
               class="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-2 border-gray-300 bg-gray-50 rounded-md">
      </div>
      <div>
        <label for="nomCommuneGN" class="block text-sm font-medium text-gray-700">Nom Commune</label>
        <input id="nomCommuneGN" type="text" name="nomCommuneGN" [(ngModel)]="searchCommand.nomCommuneGN"
               class="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-2 border-gray-300 bg-gray-50 rounded-md">
      </div>
      <div>
        <label for="codeTraiteGN" class="block text-sm font-medium text-gray-700">Code Traité GN</label>
        <input id="codeTraiteGN" type="text" name="codeTraiteGN" [(ngModel)]="searchCommand.codeTraiteGN"
               class="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-2 border-gray-300 bg-gray-50 rounded-md">
      </div>
      <div>
        <label for="codeCommuneGN" class="block text-sm font-medium text-gray-700">Code Commune GN</label>
        <input id="codeCommuneGN" type="text" name="codeCommuneGN" [(ngModel)]="searchCommand.codeCommuneGN"
               class="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-2 border-gray-300 bg-gray-50 rounded-md">
      </div>
    </div>

    <div class="flex justify-end">
      <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-150 ease-in-out">
        Rechercher
      </button>
    </div>
  </form>
</div>

<div class="container mx-auto p-4 overflow-x-auto full-size">
  <table class="min-w-full bg-white">
    <thead>
      <tr class="bg-gray-200 text-gray-600 uppercase text-sm rounded-md leading-normal">
        <th class="py-3 px-4 text-left">Niveau</th>
        <th class="py-3 px-4 text-left">Marque</th>
        <th class="py-3 px-4 text-left">Contrat</th>
        <th class="py-3 px-4 text-left">Commune</th>
        <th class="py-3 px-4 text-left">Code INSEE</th>
        <th class="py-3 px-4 text-left">Fichiers</th>
        <th class="py-3 px-4 text-left rounded-tr-lg"></th>
      </tr>
    </thead>
    <tbody class="text-gray-600 text-sm font-light">
      <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ list: items(), level: 0 }"></ng-container>
    </tbody>
  </table>
</div>

<div class="pagination-controls flex items-center justify-center space-x-4">
  <button (click)="changePage(1)" [disabled]="page() - 1  <= 0" class="px-4 py-2 text-black rounded disabled:opacity-50 disabled:cursor-default">
    <ng-icon name="heroChevronDoubleLeft"/>
  </button>
  <button (click)="changePage(page() - 1)" [disabled]="page() - 1 <= 0" class="px-4 py-2  text-black rounded disabled:opacity-50 disabled:cursor-default">
    <ng-icon name="heroChevronLeft"/>
  </button>
  <span class="text-gray-700">Page {{ page() }} sur {{ pages }}</span>
  <button (click)="changePage(page() + 1)" [disabled]="page() == pages" class="px-4 py-2  text-black rounded disabled:opacity-50 disabled:cursor-default">
    <ng-icon name="heroChevronRight"/>
  </button>
  <button (click)="changePage(pages)" [disabled]="page() == pages" class="px-4 py-2  text-black rounded disabled:opacity-50 disabled:cursor-default">
    <ng-icon name="heroChevronDoubleRight"/>
  </button>
</div>

<ng-template #recursiveListTmpl let-list="list" let-level="level">
  <ng-container *ngFor="let item of list">
    <tr class="border-b border-gray-200 hover:bg-gray-100">
      <td class="py-3 px-4 text-left whitespace-nowrap">
        <div [style.padding-left.px]="level * 20" class="flex items-center">
          <button *ngIf="item.enfants && item.enfants.length" (click)="toggleExpand(item)" class="mr-2">
            <ng-icon size="12px" name="{{ item.expanded ? 'heroChevronDown' : 'heroChevronRight'}}"/>
          </button>
          {{ item.granularite }}
        </div>
      </td>
      <td class="py-3 px-4 text-left">
        <ng-container [ngSwitch]="item.granularite">
          <span *ngSwitchCase="'MARQUE'">{{ item.marque }}</span>
        </ng-container>
      </td>
      <td class="py-3 px-4 text-left">
        <ng-container [ngSwitch]="item.granularite">
          <span *ngSwitchCase="'CONTRAT'">{{item.code_contrat}} - {{item.ctr_libelle}}</span>
        </ng-container>
      </td>
      <td class="py-3 px-4 text-left">
        <ng-container [ngSwitch]="item.granularite">
          <span *ngSwitchCase="'COMMUNE'">{{ item.com_nom }}</span>
          <span *ngSwitchCase="'QUARTIER'">{{ item.com_nom }} ({{ item.qrt_libelle }})</span>
        </ng-container>
      </td>
      <td class="py-3 px-4 text-left">
        <ng-container [ngSwitch]="item.granularite">
          <span *ngSwitchCase="'COMMUNE'">{{ item.code_insee }}</span>
          <span *ngSwitchCase="'QUARTIER'">{{ item.code_insee }}</span>
        </ng-container>
      </td>
      <td class="py-3 px-4 text-left">
        <ng-container *ngIf="item.fichiers && item.fichiers.length > 0; else noFiles">
          <span *ngFor="let fichier of item.fichiers" class="inline-block mr-2">
            <button (click)="openFile(fichier)">
              <ng-container [ngSwitch]="fichier.gfi_num_type">
                <svg-icon *ngSwitchCase="900070003" src="assets/icons/Document_QE.svg" title="{{fichier.gfi_type}}" name="Fiche Qualité Eau" [svgStyle]="{'height.px':24}"/>
                <svg-icon *ngSwitchCase="900070001" src="assets/icons/Document_RSE.svg" title="{{fichier.gfi_type}}" name="Fiche RSE" [svgStyle]="{'height.px':24}"/>
                <svg-icon *ngSwitchCase="900070002" src="assets/icons/Document_RSA.svg" title="{{fichier.gfi_type}}" name="Fiche RSA" [svgStyle]="{'height.px':24}"/>
                <svg-icon *ngSwitchCase="900070004" src="assets/icons/Document_Tarif.svg" title="{{fichier.gfi_type}}" name="Fiche Tarif" [svgStyle]="{'height.px':24}"/>
                <svg-icon *ngSwitchCase="900070005" src="assets/icons/Document_RSANC.svg" title="{{fichier.gfi_type}}" name="Fiche RSANC" [svgStyle]="{'height.px':24}"/>
                <svg-icon *ngSwitchCase="900070006" src="assets/icons/Document_GP.svg" title="{{fichier.gfi_type}}" name="Fiche GUIDE PRATIQUE" [svgStyle]="{'height.px':24}"/>
                <svg-icon *ngSwitchCase="900070007" src="assets/icons/Document_CGSS.svg" title="{{fichier.gfi_type}}" name="Fiche CGSS" [svgStyle]="{'height.px':24}"/>
                <svg-icon *ngSwitchCase="900070008" src="assets/icons/Document_MLEG.svg" title="{{fichier.gfi_type}}" name="Fiche MENTIONS LÉGALES" [svgStyle]="{'height.px':24}"/>
                <svg-icon *ngSwitchCase="900070009" src="assets/icons/Document_FREP.svg" title="{{fichier.gfi_type}}" name="Fiche Raccordement Eau Potable" [svgStyle]="{'height.px':24}"/>
                <svg-icon *ngSwitchCase="900070010" src="assets/icons/Document_FREU.svg" title="{{fichier.gfi_type}}" name="Fiche Raccordement Eaux Usée" [svgStyle]="{'height.px':24}"/>
                <svg-icon *ngSwitchCase="900070011" src="assets/icons/Document_WARS.svg" title="{{fichier.gfi_type}}" name="Fiche Warsmann" [svgStyle]="{'height.px':24}"/>
                <svg-icon *ngSwitchDefault src="assets/icons/Document_unknown.svg" title="{{fichier.gfi_type}}" name="Document" [svgStyle]="{'height.px':24}"/>
              </ng-container>
            </button>
          </span>
        </ng-container>
        <ng-template #noFiles>
          <button (click)="openModal(item)">
            <svg-icon src="assets/icons/Document_add.svg" name="Document add" [svgStyle]="{'height.px':24}"/>
          </button>

        </ng-template>
      </td>
    <td class="py-3 px-4 text-left">
      <button (click)="openModal(item)" class="text-blue-500 hover:text-blue-400">
        <ng-icon name="heroPencilSquare"/>
      </button>
    </td>
    </tr>
    <ng-container *ngIf="item.expanded && item.enfants && item.enfants.length">
      <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ list: item.enfants, level: level + 1 }"></ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<file-modal [item]="selectedItem()" (resetItemEvent)="resetItem()"></file-modal>

