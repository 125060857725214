import {Component, EventEmitter, inject, input, model, Output, output, WritableSignal} from '@angular/core';
import {GaelFichierWSDTO} from '../../model';
import {GaelService} from '../../gael.service';

@Component({
  selector: 'app-delete-confirm-modal',
  imports: [],
  templateUrl: './delete-confirm-modal.component.html',
  styleUrl: './delete-confirm-modal.component.css'
})
export class DeleteConfirmModalComponent {
  fileToDelete = model<GaelFichierWSDTO | null>(null);
  @Output() onConfirmEvent = new EventEmitter<null>();

  confirmDelete() {
    this.onConfirmEvent.emit()
  }
}
