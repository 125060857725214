<div *ngIf="item()" class="fixed inset-0 backdrop-brightness-75 backdrop-blur-sm bg-opacity-10 flex items-center justify-center">
  <div class="bg-white p-4 rounded shadow-lg w-1/2 max-h-[90vh] flex flex-col">
    <div class="flex justify-between items-center mb-4">
      <h2 class="text-xl font-bold">Fichiers</h2>
      <button (click)="closeModal()" class="text-gray-500 hover:text-gray-700">
        <ng-icon name="heroXMark"/>
      </button>
    </div>
    <div *ngIf="(item()?.fichiers?.length ?? 0) > 0; else noFiles" class="flex-grow overflow-y-auto">
      <ul>
        <li *ngFor="let fichier of item()?.fichiers ?? []" class="mb-2 border p-4 rounded shadow">
          <div class="flex justify-between items-start">
            <div>
              <i class="fas fa-file-alt"></i> {{ fichier.gfi_type }} - {{ fichier.gfi_nom }}<br>
              <strong>Date de création:</strong> {{ fichier.gfi_credate }}<br>
              <strong>Date de modification:</strong> {{ fichier.gfi_moddate }}<br>
              <strong>Code Contrat</strong> {{ item()?.code_contrat }}<br>
            </div>
          </div>
          <div *ngIf="(fichier?.gfi_metas?.length ?? 0) > 0" class="mb-8">
            <strong>Métadonnées:</strong>
            <div class="max-w-xl">
              <table class="bg-white min-w-full">
                <thead>
                <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                  <th class="py-3 px-2 text-left rounded-tl-lg">Libellé</th>
                  <th class="py-3 px-4 text-left rounded-tr-lg">Valeur</th>
                </tr>
                </thead>
                <tbody class="text-gray-600 text-sm font-light">
                <tr *ngFor="let meta of fichier.gfi_metas" class="border-b border-gray-200 hover:bg-gray-100">
                  <td class="py-3 px-2 text-left">{{ meta.gfm_clb_code }}</td>
                  <td  class="py-3 px-2 text-left">{{ meta.gfm_val }}</td>
                </tr>
              </table>
            </div>
          </div>
          <hr class="my-2">
          <div class="mt-2">
            <button (click)="viewFile(fichier)" class="bg-blue-500 text-white px-2 py-1 rounded mr-1">Consulter</button>
            <button (click)="updateFile(fichier)" class="bg-cyan-500 text-white px-2 py-1 rounded mr-1">Mettre à jour</button>
            <button (click)="updateMetadata(fichier)" class="bg-teal-500 text-white px-2 py-1 rounded mr-1">Modifier les métadonnées</button>
            <button (click)="openDeleteModal(fichier)" class="bg-red-500 text-white px-2 py-1 rounded">Supprimer</button>
          </div>
        </li>
      </ul>
    </div>
    <ng-template #noFiles>
      <p>Aucun fichier disponible.</p>
    </ng-template>
    <div class="mt-4 pt-4 border-t">
      <button (click)="addFile()" class="bg-green-500 text-white px-4 py-2 rounded w-full">Ajouter un fichier</button>
    </div>
  </div>
</div>

<div *ngIf="isFileUploadOpen()" class="fixed inset-0 backdrop-brightness-75 backdrop-blur-sm bg-opacity-10 flex items-center justify-center">
  <div class="bg-white p-4 rounded shadow-lg w-1/2 max-h-[90vh] flex flex-col">
    <div class="flex justify-between items-center mb-4">
      <h2 class="text-xl font-bold">Télécharger un fichier</h2>
      <button (click)="closeFileUploadModal()" class="text-gray-500 hover:text-gray-700">
        <ng-icon name="heroXMark"/>
      </button>
    </div>
    <app-file-upload [fileToUpdate]="fileToUpdate()" [item]="item()"></app-file-upload>
  </div>
</div>

<div *ngIf="isFileMetadataModalOpen() && fileToUpdate()" class="fixed inset-0 backdrop-brightness-75 backdrop-blur-sm bg-opacity-10 flex items-center justify-center">
  <div class="bg-white p-4 rounded shadow-lg w-1/2 max-h-[90vh] flex flex-col">
    <div class="flex justify-between items-center mb-4">
      <h2 class="text-xl font-bold">Modifier les métadonnées</h2>
      <button (click)="closeFileMetadataModal()" class="text-gray-500 hover:text-gray-700">
        <ng-icon name="heroXMark"/>
      </button>
    </div>
    <app-file-metadata *ngIf="fileToUpdate() !== null" [fileToUpdate]="fileToUpdate()!" [item]="item()"></app-file-metadata>
  </div>
</div>

<div *ngIf="isDeleteModalOpen()" class="fixed inset-0 backdrop-brightness-75 backdrop-blur-sm bg-opacity-10 flex items-center justify-center">
  <div class="bg-white p-4 rounded shadow-lg w-1/2 max-h-[90vh] flex flex-col">
    <div class="flex justify-between items-center mb-4">
      <h2 class="text-xl font-bold">Confirmer la suppression</h2>
      <button (click)="closeDeleteModal()" class="text-gray-500 hover:text-gray-700">
        <ng-icon name="heroXMark"/>
      </button>
    </div>
    <app-delete-confirm-modal (onConfirmEvent)="handleConfirmDeleteFile(fileToUpdate())" [fileToDelete]="fileToUpdate()"/>
  </div>
</div>
