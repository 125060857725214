<app-header></app-header>
<div class="min-h-screen flex items-center justify-center bg-gray-100">
  <div class="bg-white p-8 rounded-lg shadow-md min-w-[300px] min-h-[100px]">
    <div class="flex flex-col items-center justify-center gap-6">
      <p class="text-gray-700 text-center">
        Vous avez bien été déconnecté. Vous pouvez fermer la page.
      </p>
<!--      <a-->
<!--        href="/"-->
<!--        class="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-md transition-colors duration-200"-->
<!--      >-->
<!--        Retour à l'accueil-->
<!--      </a>-->
    </div>
  </div>
</div>
