import {HttpInterceptorFn, HttpResponse} from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, tap, throwError } from 'rxjs';
import { ToastService } from '../gael/toast.service';
import { RESPONSE_CONFIG } from '../response.config';

export const responseInterceptor: HttpInterceptorFn = (req, next) => {
  const toastService = inject(ToastService);
  const config = req.context.get(RESPONSE_CONFIG);
  return next(req).pipe(
    tap(response => {
      const newRes = response as HttpResponse<any>
      if (config.showToast && config.successMessage && newRes.ok) {
        toastService.show(
          config.successMessage,
          'success',
          config.duration
        );
      }
    }),
    catchError(error => {
      if (config.errorMessage) {
        toastService.show(
          config.errorMessage,
          config.type || 'error',
          config.duration
        );
      }
      return throwError(() => error);
    })
  );
};
