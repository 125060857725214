import { HttpContextToken } from '@angular/common/http';

export interface ResponseConfig {
  errorMessage?: string;
  successMessage?: string;
  duration?: number;
  type?: 'error' | 'warning' | 'info' | 'success';
  showToast?: boolean;
}

export const RESPONSE_CONFIG = new HttpContextToken<ResponseConfig>(() => ({
  errorMessage: 'Une erreur est survenue',
  duration: 3000,
  type: 'error',
  showToast: true
}));
