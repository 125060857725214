import { Component, OnInit,CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { KitAbonnement } from '../../models/kitAbonnement';
import { SelectorInsee } from '../../models/selectorInsee';
import { CloudEndpointService } from '../../services/cloud-end-point-service';
import { Subscription } from 'rxjs';



declare var jquery:any;
declare var $ :any;

@Component({
  selector: 'app-kitabonnement',
  templateUrl: './kitabonnement.component.html',
  styleUrls: ['./kitabonnement.component.scss']
})
export class KitabonnementComponent implements OnInit {
  kitAbonnements : KitAbonnement = undefined;
  selectorInsee : SelectorInsee = undefined;
  kitAbonnementSubscription: Subscription;
  selectorInseeSubscription: Subscription;
  selectorTabSubscription: Subscription;
 
  constructor(private cloudService: CloudEndpointService) { }

  ngOnInit() {
     // retrieve kitAbo
     this.cloudService.currentKitAbonnement.subscribe(kitAbo => this.kitAbonnements = kitAbo);
     if(this.kitAbonnements === undefined) {
       this.getKitAbonnement(0);
     }

     // retrieve search params
     this.cloudService.currentSelectorInsee.subscribe(selectorInsee => this.selectorInsee = selectorInsee);

    if(this.selectorInsee != undefined) {
      if(this.selectorInsee.codeRegion != undefined) {
       $("#inputRegion").val(this.selectorInsee.codeRegion);
      }
      $("#inputTraiteCommune").val(this.selectorInsee.codeTraiteCommuneGn);
      $("#inputCommune").val(this.selectorInsee.libelleCommune);
      $("#inputCodePostalInse").val(this.selectorInsee.codeInsee);
    } 

    this.initTAbRowActive();
 
  }

  async getKitAbonnement(page: number) { 
    // on supprime les message d'erreur eventuel
    $("#alert-place").empty();
    // s'il n'y a pas de critère de recherche
    this.kitAbonnements = undefined;
    if(this.selectorInsee == undefined) {
    await this.cloudService.getKitAbonnements(page).then(data => {
        this.kitAbonnements = new KitAbonnement();
        this.kitAbonnements.loadFromJson(data);
      })
      .catch(error => {
        this.kitAbonnements = new KitAbonnement();
        this.showMessage("Une erreur s'est produite", 'danger');
      });
      
      // sinon 
    } else {
      await this.cloudService.getKitAbonnementsBySearch(page, this.selectorInsee).then(data => {
        this.kitAbonnements = new KitAbonnement();
        this.kitAbonnements.loadFromJson(data);
       })
       .catch(error => {
        this.kitAbonnements = new KitAbonnement();
        this.showMessage("Une erreur s'est produite", 'danger');
      }); 
    }
    // change suscribe
    this.cloudService.changeKitAbo(this.kitAbonnements);
    this.cloudService.changeSelectorInsee(this.selectorInsee);

    // active previous tab row
    this.initTAbRowActive();
  }

  async onDownoaldFile(idComquar, fileType) {
    await this.cloudService.getDocument(idComquar, fileType).then((data) => {
      if(data.url != undefined) {
        window.open(data.url, "_blank");
      }
     }).catch(error => {
      this.showMessage("Une erreur s'est produite", 'danger');
     }); 
  }

  // upload un fichiers sélectionner dans le tableau
  async onUploadFile(fileType, event) {
    var url = undefined;
    var re = /(?:\.([^.]+))?$/;
    var ext = undefined;
    var succes = false;
    var tabId = this.getTabCheckedId();
    var nomFichier = undefined;
    if(event.target.files.length > 0 && tabId.length > 0) {
      ext = re.exec(event.target.files[0].name)[1];
    }
     // put file in bucket
     if(ext != undefined && ext =='pdf') 
     {
      var form = new FormData();
      form.append("", event.target.files[0]);
      // get presigned URL
      await this.cloudService.getPresignedUrlToUploadFile().then((data) => {
          url = data.url;
          nomFichier = data.nomFichier;
      }).catch(error => {
        this.showMessage('Une erreur s"est produite', 'danger');
      }); 

      var settings = {
        "url": url,
        "method": "PUT",
        "timeout": 0,
        "processData": false,
        "mimeType": "multipart/form-data",
        "contentType": false,
        "data": form
      };
      await $.ajax(settings)
      .done(function (response) {
        succes = true;
      })
      .fail(function() {
        this.showMessage('Une erreur s"est produite', 'danger');
      });

      if(succes) {
          // remplir les comquar
          var tabComquar = new Array();
          tabId.forEach(item => 
            tabComquar.push(this.kitAbonnements.getContents[item].getIdComquar));

        await this.cloudService.postFileUpload(tabComquar, fileType, nomFichier)
        .then((data) => {
          // reinit checkbox
          //this.reinitCheckboxTitle();
          // refresh page
          this.getKitAbonnement(this.kitAbonnements.getPageNumber);
          this.showMessage('Le document a bien été enregistré', 'succes');
        }).catch(error => {
          this.showMessage('Une erreur s"est produite', 'danger');
        }); 
        
      } 
       
    } else { 
      this.showMessage('Veuillez choisir un fichier PDF svp!', 'danger');
    }

  }

  //supprimer les fichiers selectionner dans le tableau
  async removeDocButton() {
      // get item checked
      var tabId = this.getTabCheckedId();
      var update = false;

    // remplir les comquar
    var tabComquar = new Array();
    tabId.forEach(item => tabComquar.push(this.kitAbonnements.getContents[item].getIdComquar));

    if(tabComquar.length > 0) {
      await this.cloudService.deleteDocument(tabComquar, "RSA").then((data) => {
        update = true;
      }).catch(error => {
        console.error(error);
      }); 

      await this.cloudService.deleteDocument(tabComquar, "RSE").then((data) => {
        update = true;
      }).catch(error => {
        console.error(error);
      }); 

      await this.cloudService.deleteDocument(tabComquar, "ANX").then((data) => {
        update = true;
        }).catch(error => {
          console.error(error);
      }); 
            
      // rafraichir      
      if(update) {
        // reinit checkbox
        //this.reinitCheckboxTitle();
        this.getKitAbonnement(this.kitAbonnements.getPageNumber);
        this.showMessage('Les documents ont bien été supprimé', 'succes');
      }else {
        this.showMessage("Une erreur s'est produite", 'danger');
      }
    }
  }

  async onDeleteFile(idComquar, typeFichier){
    await this.cloudService.deleteDocument(idComquar, typeFichier).then((data) => {
      this.getKitAbonnement(this.kitAbonnements.getPageNumber);
      this.showMessage('Le document a bien été supprimé', 'succes');
     }).catch(error => {
      this.showMessage("Une erreur s'est produite", 'danger');
     }); 
  }

  paginationBackClick() {
    var pageNumber = this.kitAbonnements.getPageNumber - 1;
    if(pageNumber > -1) {
      this.kitAbonnements = undefined;
      // reinit checkbox
      this.reinitCheckboxTitle();
      this.getKitAbonnement(pageNumber);
      $("#previousButton").removeClass("inactive");
      $("#nextButton").removeClass("inactive");
    }
    else {
      $("#previousButton").addClass("inactive");
    }
  }

  paginationNextClick() {
    var pageNumber = this.kitAbonnements.getPageNumber + 1;
    if(pageNumber < this.kitAbonnements.getTotalPage) {
      this.kitAbonnements = undefined;
      // reinit checkbox
      this.reinitCheckboxTitle();
      this.getKitAbonnement(pageNumber);
      $("#previousButton").removeClass("inactive");
      $("#nextButton").removeClass("inactive");
    }
    else {
      $("#nextButton").addClass("inactive");
    }
  }

  onClickReinitialisation() {
    // JS clear input
   $("#inputRegion").prop('selectedIndex',0);
   $("#inputTraiteCommune").val("");
   $("#inputCommune").val("");
   $("#inputCodePostalInse").val("");
   // clear inputSuscription
   this.cloudService.changeSelectorInsee(undefined);
   // reinit checkbox
   this.reinitCheckboxTitle();
     // back to first page
   this.getKitAbonnement(0);

  }

  async onClickRechercher() {
     this.kitAbonnements = undefined;
     this.selectorInsee = new SelectorInsee();
     var search = true;
     if(this.notEmpty("inputRegion")) {
        this.selectorInsee.codeRegion =  $("#inputRegion").val();
      }

      if(this.notEmpty("inputTraiteCommune")) {
        //// s'il est du type "XXXXXX" ou "XXX XXX" ou "XXX-XXX"
        if(($("#inputTraiteCommune").val().match(/^[0-9]{6}$/)) || 
        ($("#inputTraiteCommune").val().match(/^[0-9]{3} [0-9]{3}$/)) ||
         ($("#inputTraiteCommune").val().match(/^[0-9]{3}-[0-9]{3}$/))) {
        this.selectorInsee.codeTraiteCommuneGn = $("#inputTraiteCommune").val();
      } 
      else {
        this.showMessage("Le champ Traité - commune est mal renseigné", "error");
        this.kitAbonnements = new KitAbonnement();
        return;
      }
    }

      if(this.notEmpty("inputCommune") && $("#inputCommune").val()) {
        this.selectorInsee.libelleCommune = $("#inputCommune").val();
      }
      

      if(this.notEmpty("inputCodePostalInse")) { 
        if($("#inputCodePostalInse").val().match(/^[0-9]{5}$/)) {
        this.selectorInsee.codeInsee = $("#inputCodePostalInse").val();
      }
      else {
        this.showMessage("Le champ Code INSEE est mal renseigné", "error");
        this.kitAbonnements = new KitAbonnement();
        return;
      }
    }

      
       // reinit checkbox
       this.reinitCheckboxTitle();
      this.getKitAbonnement(0);
  }

  // function to check if val of input is not empty
  notEmpty(id){
    if($("#" + id).val() != "") return true;
    return false;
  }

  //function pour chocher ou décocher au click sur le checkbox du titre
  onCheckboxTitleClick() {
    if($(".table th input[type='checkbox']").prop('checked')) {
      $(".table td input[type='checkbox']").prop('checked', true);
      $(".table th input[type='checkbox']").attr('name', 'allCheck');
      $(".table td input[type='checkbox']").parent().parent().parent().addClass("table-active");
      this.activeDownoaldButtons();
    }
    else {
      $(".table td input[type='checkbox']").parent().parent().parent().removeClass("table-active");
      $(".table th input[type='checkbox']").attr('name', 'partialCheck');
      $(".table td input[type='checkbox']").prop('checked', false);
      this.inactiveDownoaldButtons();
    }
    // on change le selector tab
    this.cloudService.changeSelectorTab(this.getTabCheckedId());
  }

  reinitCheckboxTitle() {
    // reinit checkbox
    $(".table th input[type='checkbox']").prop('checked', false);
    $(".table th input[type='checkbox']").attr('name', 'partialCheck');
    this.cloudService.changeSelectorTab(undefined);
   
  }

  // ctr K C | ctr :
  // action when we click on table row checkbox
  onCheckboxRowClick(id) {
    if($("#" + id).prop('checked')) {
        $("#" + id).parent().parent().parent().addClass("table-active");
    }
    else {
      $("#" + id).parent().parent().parent().removeClass("table-active");
    }

    // impact on title checkbox
    if ($(".table td input:checkbox:checked").length > 0)
        {
          $(".table th input[type='checkbox']").prop('checked', true);
          this.activeDownoaldButtons();
        }
    else {
      $(".table th input[type='checkbox']").prop('checked', false);
      this.inactiveDownoaldButtons();
    }

    // style when all row checbok are checked
    if ($(".table td input:checkbox:checked").length == $(".table td input:checkbox").length) {
      $(".table th input[type='checkbox']").attr('name', 'allCheck');
    }

    // on change le selector tab
    this.cloudService.changeSelectorTab(this.getTabCheckedId());
  }

inputTraiteCommuneClick() {
  
}

  inputCodePostalInseClick() {

  }

  activeDownoaldButtons() {
    $("#downoaldRseButton").removeClass("btn-custom-inactive").addClass("btn-custom-active");
    $("#downoaldRsaButton").removeClass("btn-custom-inactive").addClass("btn-custom-active");
    $("#downoaldDocButton").removeClass("btn-custom-inactive").addClass("btn-custom-active");
    $("#removeDocButton").removeClass("btn-custom-inactive").addClass("btn-custom-danger");
  }

  inactiveDownoaldButtons() {
    $("#downoaldRseButton").removeClass("btn-custom-active").addClass("btn-custom-inactive");
    $("#downoaldRsaButton").removeClass("btn-custom-active").addClass("btn-custom-inactive");
    $("#downoaldDocButton").removeClass("btn-custom-active").addClass("btn-custom-inactive");
    $("#removeDocButton").removeClass("btn-custom-danger").addClass("btn-custom-inactive");
  }

  getTabCheckedId() : Array<any>{
    var tabId = new Array();
    $(".table td input[type='checkbox']:checked").each(function() {
      var id = $(this).prop('id');
      id = parseInt(id.split("-")[1]);
      tabId.push(id);
    });
    return tabId;
  }

  showMessage(message, type) {
    $("#alert-place").empty();
    if(type == "succes") {
      $("#alert-place").html(
      '<div class="alert alert-success alert-custom alert-dismissible fade show" role="alert">' +
          '<strong>' + message + '<strong>' +
          '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
        '<span aria-hidden="true">&times;</span>' +
      '</button>' +
      '</div>' 
      );
    }
    else {
      $("#alert-place").html(
        '<div class="alert alert-danger alert-custom alert-dismissible fade show" role="alert">' +
            message +
            '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
          '<span aria-hidden="true">&times;</span>' +
        '</button>' +
        '</div>' 
      );
    }
  }

  validOnEnter(event) {
    if (event.keyCode === 13) {
        event.preventDefault();
       this.onClickRechercher();
    }
}

initTAbRowActive() {
  this.inactiveDownoaldButtons();
  var tab: Array<any> = undefined;
  this.cloudService.currentSelectorTab.subscribe(table => tab = table);
  if(tab == undefined) return;
  var checkExist = setInterval(function() {
    if ($('.table td').length) {
       $(".table td input[type='checkbox']").each(function() {
        var id = $(this).prop('id');
        id = parseInt(id.split("-")[1]);
        if(tab.includes(id)) {
          $(this).prop( "checked", true);
          $(this).parent().parent().parent().addClass("table-active");
          $(".table th input[type='checkbox']").prop('checked', true);
          $("#downoaldRseButton").removeClass("btn-custom-inactive").addClass("btn-custom-active");
          $("#downoaldRsaButton").removeClass("btn-custom-inactive").addClass("btn-custom-active");
          $("#downoaldDocButton").removeClass("btn-custom-inactive").addClass("btn-custom-active");
          $("#removeDocButton").removeClass("btn-custom-inactive").addClass("btn-custom-danger");
          // style when all row checbok are checked
          if ($(".table td input:checkbox:checked").length == $(".table td input:checkbox").length) {
            $(".table th input[type='checkbox']").attr('name', 'allCheck');
          }
        }
        
      });
       clearInterval(checkExist);
    }
  }, 100);
  }

}
