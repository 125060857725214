import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CodeLibelleWsdto } from './model';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ParametrageService {
  #http = inject(HttpClient)
  baseUrl = environment.apiUrl

  constructor() { }

  getCodeLibelleByTable(clb_table: string): Observable<CodeLibelleWsdto[]> {
    const url = this.baseUrl + `/parametrage/codelibel/table/${encodeURIComponent(clb_table)}`;
    // const url = 'codelibGaeltype.json';
    console.log('Request URL: ${url}');
    return this.#http.get<CodeLibelleWsdto[]>(url);
  }




}


