<div class="page-principale">
    <nav class="topBottomBorder navbarsh rounded">
        <div class="nav nav-tabs navtablclass topBottomBorder-div" id="nav-tab" role="tablist">
            <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" (click)="onUpdateUi(0)" role="tab" aria-controls="nav-home" aria-selected="true"><span class="navText">Kit Abonnement</span></a>
            <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" (click)="onUpdateUi(1)" role="tab" aria-controls="nav-profile" aria-selected="false"><span class="navText">Autre document AEL</span></a>
            <!-- <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" (click)="onUpdateUi(2)" role="tab" aria-controls="nav-contact" aria-selected="false"><span class="navText">Table valeur</span></a> -->
        </div>
    </nav>
    <!-- container root-->
    <div *ngIf="onglet === 'kitAbonnement'" class=" container-fluid container-no-margin ">
        <app-kitabonnement></app-kitabonnement>
    </div>
    <div *ngIf="onglet === 'autreDemande'" class="container-fluid container-no-margin ">
        <app-autredemande></app-autredemande>
    </div>


</div>