import {HttpClient, HttpContext, HttpHeaders, HttpParams} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {catchError, finalize, map, Observable, of, tap} from 'rxjs';
import {GaelFichierWSDTO, GaelSearchResponse, Item, PresignedPostWSDTO, PresignedUrlWSDTO} from './model';
import {environment} from '../../environments/environment';
import {RESPONSE_CONFIG} from '../response.config';


@Injectable({
  providedIn: 'root'
})
export class GaelService {

  #http = inject(HttpClient)
  baseUrl = environment.apiUrl


  searchDocuments(page?: number, size?: number, system_source?: string,
                  code_insee?: string[], code_contrat?: string[],
                  code_marque?: string[],code_traite_gn?: string[],
                  code_exp_gn?: string[],code_com_gn?: string[],
                  com_nom?: string[]): Observable<GaelSearchResponse> {

      let params = new HttpParams();

      if (page !== undefined) params = params.set('page', page.toString());
      if (size !== undefined) params = params.set('size', size.toString());
      if (system_source) params = params.set('system_source', system_source);

      if (code_insee && code_insee.length) {
        code_insee.forEach(code => {
          params = params.append('code_insee', code);
        });
      }
      if (code_contrat && code_contrat.length) {
        code_contrat.forEach(code => {
          params = params.append('code_contrat', code);
        });
      }
      if (code_marque && code_marque.length) {
        code_marque.forEach(code => {
          params = params.append('code_marque', code);
        });
      }
      if (code_traite_gn && code_traite_gn.length) {
        code_traite_gn.forEach(code => {
          params = params.append('code_traite_gn', code);
        });
      }
      if (code_exp_gn && code_exp_gn.length) {
        code_exp_gn.forEach(code => {
          params = params.append('code_exp_gn', code);
        });
      }
      if (code_com_gn && code_com_gn.length) {
        code_com_gn.forEach(code => {
          params = params.append('code_com_gn', code);
        });
      }
      if (com_nom && com_nom.length) {
        com_nom.forEach(code => {
          params = params.append('com_nom', code);
        });
      }
      const url = this.baseUrl + '/gael/document/search';
      return this.#http.get<GaelSearchResponse>(url, { params });
  }

  getDowloadPresignedUrl(file_name:string): Observable<PresignedUrlWSDTO> {
    ///gael/document/consult?file_name=filename
    const url = this.baseUrl + '/gael/document/consult';
    let params = new HttpParams();
    params = params.set('file_name', encodeURIComponent(file_name));
    return this.#http.get<PresignedUrlWSDTO>(url,{ params });
  }

  getUploadPresignedUrl(): Observable<PresignedPostWSDTO> {
    const url = this.baseUrl + '/gael/document/presigned';
    let params = new HttpParams();
    params = params.set('type', 'pdf');
    return this.#http.get<PresignedPostWSDTO>(url, { params });
  }

  uploadFile(file: File, uploadData: PresignedPostWSDTO): Observable<any> {
    const formData = new FormData();
    formData.append('key', uploadData.fields.key);
    formData.append('Content-Type', file.type);
    formData.append('policy', uploadData.fields.policy);
    formData.append('x-amz-algorithm', uploadData.fields['x-amz-algorithm']);
    formData.append('x-amz-credential', uploadData.fields['x-amz-credential']);
    formData.append('x-amz-date', uploadData.fields['x-amz-date']);
    formData.append('x-amz-signature', uploadData.fields['x-amz-signature']);
    formData.append('x-amz-security-token', uploadData.fields['x-amz-security-token']);

    // Ajout du fichier
    formData.append('file', file);

    // Configuration des en-têtes
    const headers = new HttpHeaders({
      // Nous ne définissons pas le Content-Type ici car il sera automatiquement
      // défini comme 'multipart/form-data' avec la boundary appropriée
    });

    // Envoi de la requête POST
    return this.#http.post(uploadData.url, formData, { headers: headers });
    // TODO: Remplacer le retour par un appel à l'API
    // return of({ success: true });
  }

  updateFile(file:GaelFichierWSDTO,file_name:string): Observable<GaelFichierWSDTO> {
    //PUT gael/document/update
    const url = this.baseUrl + '/gael/document/update';

    const body: any= {
      gfi_id: file.gfi_id,
      gfi_nom: file_name,
      clb_num_gfi_type: file.gfi_num_type,
      gfi_granularite: file.gfi_granularite,
      gfi_credate: file.gfi_credate
    };
    if (file.mar_numero !== undefined && file.mar_numero !== null) {
      body.mar_numero = file.mar_numero;
    }
    if (file.com_numero !== undefined && file.com_numero !== null) {
      body.com_numero = file.com_numero;
    }
    if (file.qrt_numero !== undefined && file.qrt_numero !== null) {
      body.qrt_numero = file.qrt_numero;
    }
    if (file.zct_numero !== undefined && file.zct_numero !== null) {
      body.zct_numero = file.zct_numero;
    }
    if (file.ctr_numero !== undefined && file.ctr_numero !== null) {
      body.ctr_numero = file.ctr_numero;
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.#http.put<GaelFichierWSDTO>(url,body,{ headers , context: new HttpContext().set(RESPONSE_CONFIG, {
        successMessage: "Document mis à jour avec succès",
        errorMessage: 'Une erreur est survenue lors de la mise à jour du document. Veuillez réessayer plus tard.',
        duration: 5000,
        type: 'error',
        showToast: true
      })});
  }

  deleteFile(file:GaelFichierWSDTO): Observable<any> {
        //Delete gael/document/delete/{gfi_id}
        const url = this.baseUrl + `/gael/document/delete/${file.gfi_id}`;

        return this.#http.delete<any>(url, {context: new HttpContext().set(RESPONSE_CONFIG, {
            successMessage: "Document supprimé avec succès",
            errorMessage: 'Une erreur est survenue lors de la suppression du document. Veuillez réessayer plus tard.',
            duration: 5000,
            type: 'error',
            showToast: true
          })});
  }

  createFile(item:Item,file_name:string,clb_type_file:number): Observable<GaelFichierWSDTO> {
    const url = this.baseUrl + "/gael/document/create";

    const body: any= {
      gfi_nom: file_name,
      clb_num_gfi_type: clb_type_file,
      gfi_granularite: item.granularite,
      gfi_credate: new Date().toISOString()
    };
    if (item.mar_numero !== undefined && item.mar_numero !== null) {
      body.mar_numero = item.mar_numero;
    }
    if (item.com_numero !== undefined && item.com_numero !== null) {
      body.com_numero = item.com_numero;
    }
    if (item.qrt_numero !== undefined && item.qrt_numero !== null) {
      body.qrt_numero = item.qrt_numero;
    }
    if (item.zct_numero !== undefined && item.zct_numero !== null) {
      body.zct_numero = item.zct_numero;
    }
    if (item.ctr_numero !== undefined && item.ctr_numero !== null) {
      body.ctr_numero = item.ctr_numero;
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.#http.post<GaelFichierWSDTO>(url,body,{ headers , context: new HttpContext().set(RESPONSE_CONFIG, {
      successMessage: "Document crée avec succès",
      errorMessage: 'Une erreur est survenue lors de la création du document. Veuillez réessayer plus tard.',
      duration: 5000,
      type: 'error',
      showToast: true
    })});
  }

  viewFile(fichier: GaelFichierWSDTO) {
    this.getDowloadPresignedUrl(fichier.gfi_nom).pipe(
      map((response: PresignedUrlWSDTO) => response.url),
      tap(url => {
        window.open(url, '_blank');
      }),
      catchError(error => {
        console.error('Erreur lors de l\'ouverture du fichier:', error);
        // Gérer l'erreur
        return of(null);
      }),
      finalize(() => {
      })
    ).subscribe();
  }

}
