import { from } from 'rxjs';

export class SelectorInsee {
    private _idComquar: string;
    private _codeRegion: string;
    private _codeInsee: string;
    private _codeTraiteCommuneGn : string;
    private _libelleCommune: string;
    private _codePostal: string;
    private pageNumber: number;
   
   
    
    constructor() {}

    public loadFromJson = function loadFromJson(data: any) {
      if(data == undefined) return undefined;
      this.idComquar = data._idComquar;
      this.codeRegion = data._codeRegion;
      this.codeInsee = data._codeInsee;
      this.codeTraiteCommuneGn = data._codeTraiteCommuneGn;
      this.libelleCommune = data._libelleCommune;
      this.codePostal = data._codePostal;
    }
    
      public get idComquar(): string {
        return this._idComquar;
     }

     public set idComquar(idComquar: string) {
         this._idComquar = idComquar;
     }

     public get codeRegion(): string {
        return this._codeRegion;
     }

     public set codeRegion(codeRegion: string) {
         this._codeRegion = codeRegion;
     }

     public get codeInsee(): string {
        return this._codeInsee;
     }

     public set codeInsee(codeInsee: string) {
         this._codeInsee = codeInsee;
     }

     public get codeTraiteCommuneGn(): string {
      return this._codeTraiteCommuneGn;
    }

    public set codeTraiteCommuneGn(codeTraiteCommuneGn: string) {
        this._codeTraiteCommuneGn = codeTraiteCommuneGn;
    }

     public get libelleCommune(): string {
        return this._libelleCommune;
     }

     public set libelleCommune(libelleCommune: string) {
         this._libelleCommune = libelleCommune;
     }

     
     public get codePostal(): string {
        return this._codePostal;
     }

     public set codePostal(codePostal: string) {
         this._codePostal = codePostal;
     }
     

     public get queryString() : string{
      var query = "&codeRegion=" + this.codeRegion;
      if(this.codeTraiteCommuneGn != undefined) {
        // code region doit être  du type "XXXXXX" pour être accepté en base
        var result = this.codeTraiteCommuneGn;
        // s'il est du type "XXXXXX"
        if(this._codeTraiteCommuneGn.match(/^[0-9]{6}$/)) {
          result = this.codeTraiteCommuneGn;
        }
        // s'il est du type "XXX XXX"
        if(this._codeTraiteCommuneGn.match(/^[0-9]{3} [0-9]{3}$/)) {
           result = this.codeTraiteCommuneGn.replace(/\s/g, ""); 
         }
        // s'il est du type "XXX-XXX"
        if(this._codeTraiteCommuneGn.match(/^[0-9]{3}-[0-9]{3}$/)) {
          result = result = this.codeTraiteCommuneGn.slice(0,3) + this.codeTraiteCommuneGn.slice(4,7);
        }
        query = query + "&codeTraiteCommuneGn=" + result;
      }
      if(this.libelleCommune != undefined) {
        //encodeURIComponent(myUrl);
        query = query + "&libelleCommune=" + encodeURIComponent(this.libelleCommune); 
      }
      if(this.codeInsee != undefined) {
        query = query + "&codeInsee=" + this.codeInsee; 
      }
      return query;
     }

  


  }