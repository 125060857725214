<div>
  <div>
    <ng-container *ngIf="fileToUpdate.gfi_metas.length > 0 || createMetadataOpen(); else noMeta">
      <form [formGroup]="metadataCreateForm">
      </form>
      <form [formGroup]="metadataUpdateForm">
      </form>
      <table class="bg-white min-w-full">
        <thead>
        <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
          <th class="py-3 px-2 text-left rounded-tl-lg">Libellé</th>
          <th class="py-3 px-2 text-left">Valeur</th>
          <th class="rounded-tr-lg"></th>
        </tr>
        </thead>
        <tbody class="text-gray-600 text-sm font-light">
        <tr *ngFor="let meta of fileToUpdate.gfi_metas" class="border-b border-gray-200 hover:bg-gray-100">
          <ng-container *ngIf="updateMetadataOpen() && meta.gfm_id == selectedMetadata()?.gfm_id else notEditing" [formGroup]="metadataUpdateForm">
            <td class="py-3 px-2 text-left">
              <select
                id="fileTypeUpdate"
                formControlName="libelle"
                class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                required
              >
                <option *ngFor="let codeLibelle of avaliableMeta()" [value]="codeLibelle.numero" [selected]="codeLibelle.numero === meta.clb_numero_meta">
                  {{ codeLibelle.libelle }}
                </option>
              </select>
            </td>
            <td class="py-3 px-2 text-left"><input formControlName="value" type="text" [name]="'value'" required></td>
            <td class="py-3 text-left">
              <button class="mr-2" (click)="submitUpdateMetadata()">
                <ng-icon name="heroCheck"/>
              </button>
              <button class="mr-2" (click)="closeUpdateMetadata()">
                <ng-icon name="heroXMark"/>
              </button>
            </td>
          </ng-container>
          <ng-template #notEditing>
            <td class="py-3 px-2 text-left">{{ meta.gfm_clb_code }}</td>
            <td  class="py-3 px-2 text-left">{{ meta.gfm_val }}</td>
            <td  class="py-3 text-left">
              <button class="mr-2" (click)="updateMetadata(meta)">
                <ng-icon name="heroPencilSquare"/>
              </button>
              <button class="mr-2" (click)="deleteMetadata(meta)">
                <ng-icon name="heroTrash"/>
              </button>
            </td>
          </ng-template>
        </tr>

        <tr *ngIf="createMetadataOpen()" [formGroup]="metadataCreateForm">
          <td class="py-3 px-2 text-left">
            <select
              id="fileType"
              formControlName="libelle"
              class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              required
              >
              <option *ngFor="let codeLibelle of avaliableMeta()" [value]="codeLibelle.numero">
                {{ codeLibelle.libelle }}
              </option>
            </select>
          </td>
          <td class="py-3 px-2 text-left"><input [formControlName]="'value'" type="text" [name]="'value'" required></td>
          <td class="py-3 text-left">
            <button class="mr-2" (click)="submitAddMetadata()">
              <ng-icon name="heroCheck"/>
            </button>
            <button class="mr-2" (click)="closeAddMetadata()">
              <ng-icon name="heroXMark"/>
            </button>
          </td>
        </tr>
      </table>
      <div *ngIf="metadataErrorVisible()" class="text-red-600">
        Tous les champs sont obligatoires
      </div>
    </ng-container>
    <ng-template #noMeta>
      <div>
        Aucune métadonnée associée à ce document.
      </div>
    </ng-template>
  </div>
  <div class="float-right mt-4">
    <button *ngIf="!createMetadataOpen() && canAddMetadata()" (click)="addMetadata()" class="bg-blue-500 text-white px-2 py-1 rounded mr-1">
      Ajouter une metadonnée
    </button>
  </div>
  <div class="mb-8"></div>
</div>

