import {Component, inject, Input, signal} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {GaelFichierWSDTO, GfiMeta, Item} from '../../model';
import {NgForOf, NgIf} from '@angular/common';
import {NgIcon, provideIcons} from '@ng-icons/core';
import {heroCheck, heroPencilSquare, heroTrash, heroXMark} from '@ng-icons/heroicons/outline';
import {MetadataService} from '../../metadata.service';
import {catchError, of, tap} from 'rxjs';
import {CodeLibelleWsdto} from '../../../parametrages/model';
import {ParametrageService} from '../../../parametrages/parametrages.service';

@Component({
  selector: 'app-file-metadata',
  imports: [
    ReactiveFormsModule,
    NgForOf,
    NgIcon,
    NgIf
  ],
  templateUrl: './file-metadata.component.html',
  styleUrl: './file-metadata.component.css',
  providers: [provideIcons({heroPencilSquare,heroTrash, heroXMark, heroCheck})],
})
export class FileMetadataComponent {
  @Input() fileToUpdate!: GaelFichierWSDTO;
  @Input() item!: Item | null;
  private metadataService = inject(MetadataService)
  private parametrageService = inject(ParametrageService)
  canAddMetadata = signal<boolean>(false)
  createMetadataOpen = signal<boolean>(false)
  updateMetadataOpen = signal<boolean>(false)
  selectedMetadata = signal<GfiMeta|null>(null)
  metadataErrorVisible = signal<boolean>(false)
  codeMeta = signal<CodeLibelleWsdto[]>([]);
  avaliableMeta = signal<CodeLibelleWsdto[]>([]);
  metadataCreateForm: FormGroup;
  metadataUpdateForm: FormGroup;

  constructor() {
    this.metadataCreateForm = new FormGroup({
      libelle: new FormControl('', Validators.required),
      value: new FormControl('', Validators.required),
    })
    this.metadataUpdateForm = new FormGroup({
      libelle: new FormControl('', Validators.required),
      value: new FormControl('', Validators.required),
    })
  }

  ngOnInit() {
    this.fetchCodeMeta();
  }

  checkCanAddMetadata() {
    if (this.avaliableMeta().length <= 0)
      this.canAddMetadata.set(false)
    for (let codeMeta of this.avaliableMeta()) {
      if (this.fileToUpdate.gfi_metas.some(meta => meta.clb_numero_meta !== codeMeta.numero) || this.fileToUpdate.gfi_metas.length === 0) {
        this.canAddMetadata.set(true)
        break
      } else {
        this.canAddMetadata.set(false)
      }
    }
  }

  filterMetadata(selectedMeta: GfiMeta) {
    this.avaliableMeta.set([
        this.codeMeta()[this.codeMeta().findIndex(item => item.numero === selectedMeta.clb_numero_meta)],
        ...this.avaliableMeta().filter(item => this.fileToUpdate.gfi_metas.some(meta => meta.clb_numero_meta !== item.numero))
      ]
    )
  }

  fetchCodeMeta() {
    this.parametrageService.getCodeLibelleByTable('GAELMETA').pipe().subscribe({
      next: (data: CodeLibelleWsdto[]) => {
        this.codeMeta.set([...data]);
        this.avaliableMeta.set([...data]);
        if (this.selectedMetadata() !== null) {
          this.filterMetadata(this.selectedMetadata()!)
        }
        for (let codeMeta of data) {
          if (!this.fileToUpdate.gfi_metas.some(meta => meta.clb_numero_meta === codeMeta.numero)) {
            this.canAddMetadata.set(true)
            break
          }
        }
      },
      error: (error) => {
        console.error('Erreur lors de la récupération des codes libellés:', error);
      }
    });
  }

  updateMetadata(meta: GfiMeta) {
    this.selectedMetadata.set(meta)
    this.updateMetadataOpen.set(true)
    this.metadataUpdateForm.patchValue({
      libelle: meta.clb_numero_meta,
      value: meta.gfm_val
    })
  }

  deleteMetadata(meta: GfiMeta) {
    this.metadataService.deleteMeta(meta.gfm_id).pipe(
      tap(() => {
        this.fileToUpdate?.gfi_metas.splice(
          this.fileToUpdate?.gfi_metas.findIndex(item => item.gfm_id === meta.gfm_id),
          1
        )
        this.checkCanAddMetadata();
      }),
      catchError(err => {
        console.error('Erreur lors de la suppression de la metadata:', err);
        return of(null);
      })
    ).subscribe();
  }

  addMetadata() {
    this.createMetadataOpen.set(true);
  }

  closeAddMetadata() {
    this.createMetadataOpen.set(false);
  }

  submitAddMetadata() {
    if (this.metadataCreateForm.invalid) {
      this.metadataErrorVisible.set(true)
      return
    }
    this.metadataErrorVisible.set(false)
    this.metadataService.createMeta(
      this.fileToUpdate.gfi_id,
      this.metadataCreateForm.value["libelle"],
      this.metadataCreateForm.value["value"]
    ).subscribe(
      {next: (data: GfiMeta) => {
      this.fileToUpdate.gfi_metas.push(data);
      this.filterMetadata(data);
      this.checkCanAddMetadata();
    },
      error: (error) => {
      console.error('Erreur l\'ajout de la metadata:', error);
    }}
    )
    this.closeAddMetadata()
  }

  closeUpdateMetadata() {
    this.updateMetadataOpen.set(false);
  }

  submitUpdateMetadata() {
    if (this.metadataUpdateForm.invalid) {
      this.metadataErrorVisible.set(true)
      return
    }
    this.metadataErrorVisible.set(false)
    this.metadataService.updateMeta(
      this.fileToUpdate.gfi_id,
      this.selectedMetadata()?.gfm_id ?? 0,
      this.metadataUpdateForm.value["libelle"],
      this.metadataUpdateForm.value["value"]
    ).subscribe(
      {next: (data: GfiMeta) => {
          this.fileToUpdate?.gfi_metas.splice(
            this.fileToUpdate?.gfi_metas.findIndex(item => item.gfm_id === this.selectedMetadata()?.gfm_id),
            1
          )
          this.fileToUpdate.gfi_metas.push(data);
          this.filterMetadata(data)
          this.checkCanAddMetadata()
        },
        error: (error) => {
          console.error('Erreur la mise à jour de la metadata:', error);
        }}
    )
    this.closeUpdateMetadata()
  }

}
