import {Component, inject} from '@angular/core';
import {HeaderComponent} from '../header/header.component';
import {AuthService} from '../../../guard/auth.service';

@Component({
  selector: 'app-unauthorized',
  imports: [
    HeaderComponent
  ],
  templateUrl: './unauthorized.component.html',
  styleUrl: './unauthorized.component.css'
})
export class UnauthorizedComponent {
  private authService = inject(AuthService);

  login() {
    this.authService.login()
  }

}
