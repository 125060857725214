export const environment = {
  production: false,
  environment: 'Development',
  API_URL: 'https://02saooqmsk.execute-api.eu-west-1.amazonaws.com/dev',
  apiBackofficeName : 'ael_api_dev',

  //gatewayBaseUrl:'https://0or8fglhc1.execute-api.eu-west-1.amazonaws.com/rec',
  // Global region for AWS Amplify configuration
  globalRegion: 'eu-west-1',

  // Identity region to Ireland
  identityRegion: 'eu-west-1',

  // API region for URL signing
  apiRegion: 'eu-west-1',

  // DEV environment identifiers (environment deployed in AWS)
  //RL4ptlnIu-78SLUNXohFAgL0
  googleClientId: '85016409298-8mtgtlmgkjjv9081rob2gnh86b46i0dl.apps.googleusercontent.com',
  identityPoolId: 'eu-west-1:542ff681-1b63-4937-a64c-a9bccbf49844',
 // identityPoolId: 'eu-west-1:df90d71e-e99d-4d4d-8872-1a2f94d57061',
 // API_URL: 'https://wasadyehu7.execute-api.eu-west-1.amazonaws.com/dev'
 build: "3",
 commit: "42373866babc66e4c8c669463fc1b5090be3c721",

};
