import { Component, OnInit,CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';

declare var jquery:any;
declare var $ :any;

@Component({
  selector: 'app-demandes',
  templateUrl: './demandes.component.html',
  styleUrls: ['./demandes.component.scss']
})
export class DemandesComponent implements OnInit {

   barmenuTab:boolean[]=[];
   onglet: any;

  constructor() { 
    this.onglet = "kitAbonnement";
  }

  ngOnInit() {

  }

  onUpdateUi(index){
    var i=0;
    for(i=0; i<3;i++){
      this.barmenuTab[i]=false;
      if(i==index)
        this.barmenuTab[i]=true;
        if(index == 0) {
          this.onglet = "kitAbonnement";
        }
        if(index == 1) {
          this.onglet = "autreDemande";
        }

       

    }

  }
}
