<div class="max-w-md mx-auto mt-8">
  <form [formGroup]="fileUploadForm">
    <div class="mt-4">
      <div *ngIf="!showUploadForm()" class="mb-4">
        Pour commencer, veuillez sélectionner un type de fichier.
      </div>
      <label for="fileType" class="block text-sm font-medium text-gray-700">Type de fichier</label>
      <select
        id="fileType"
        formControlName="fileType"
        class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
        required
        (change)="onChangeSelect($event)"
      >
        <option *ngFor="let codeLibelle of codeLibelles()" [value]="codeLibelle.numero">
          {{ codeLibelle.libelle }}
        </option>
      </select>
    </div>
  <div
    class="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center cursor-pointer transition duration-300 ease-in-out relative mt-8"
    [class.bg-gray-100]="isDragging()"
    [class.pointer-events-none]="isUploading()"
    (dragover)="onDragOver($event)"
    (dragleave)="onDragLeave($event)"
    (drop)="onDrop($event)"
    *ngIf="showUploadForm()"
  >
    <ng-container *ngIf="!isUploading() && uploadStatus() === null">
      <p class="text-gray-600 mb-2">Glissez et déposez un fichier PDF ici</p>
      <p class="text-gray-500 mb-4">ou</p>
      <label class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded cursor-pointer transition duration-300 ease-in-out">
        Choisir un fichier
        <input type="file" class="hidden" accept="application/pdf" (change)="onFileSelected($event)" />
      </label>
      <div *ngIf="showFileTypeError()" class="text-red-500 flex justify-center text-center items-center gap-1 font-bold mt-4">
        <ng-icon name="heroExclamationCircle"/>
        <span>Veuillez choisir un fichier PDF.</span>
      </div>
    </ng-container>

    <div *ngIf="isUploading()" class="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
      <div class="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
    </div>

    <div *ngIf="uploadStatus() === 'success'">
      <div class="flex justify-center text-center items-center gap-1 text-green-500 font-bold">
        <ng-icon name="heroCheckCircle"/>
        <span> Téléchargement réussi !</span>
      </div>
      <div class="mt-2 text-sm">Vous pouvez a présent fermer cette pop-up.</div>
    </div>


    <div *ngIf="uploadStatus() === 'error'" class="">
      <div class="text-red-500 flex justify-center text-center items-center gap-1 font-bold">
        <ng-icon name="heroExclamationCircle"/>
        <span>Échec du téléchargement. Veuillez réessayer.</span>
      </div>
      <div class="flex justify-center text-center items-center gap-1 mt-4">
        <button class="bg-green-500 text-white px-4 py-2 rounded p-4" (click)="uploadStatus.set(null)">Réessayer</button>
      </div>

    </div>
  </div>
    <div class="mb-8"></div>
  </form>
</div>

