import {
  provideKeycloak,
  createInterceptorCondition,
  IncludeBearerTokenCondition,
  INCLUDE_BEARER_TOKEN_INTERCEPTOR_CONFIG,
  withAutoRefreshToken,
  AutoRefreshTokenService,
  UserActivityService
} from 'keycloak-angular';
import {environment} from '../environments/environment';

const baseUrl = environment.apiUrl

const backendCondition = createInterceptorCondition<IncludeBearerTokenCondition>({
  urlPattern: new RegExp(baseUrl.replace(/[/.]/g, '\\$&'), "i")
});

export const provideKeycloakAngular = () =>
  provideKeycloak({
    config: {
      realm: 'vef',
      url: 'https://keycloak-hom.dir.istefr.fr/',
      clientId: 'GAELLE'
    },
    initOptions: {
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      redirectUri: window.location.origin + '/'
    },
    features: [
      withAutoRefreshToken({
        onInactivityTimeout: 'logout',
        sessionTimeout: 600000
      })
    ],
    providers: [
      AutoRefreshTokenService,
      UserActivityService,
      {
        provide: INCLUDE_BEARER_TOKEN_INTERCEPTOR_CONFIG,
        useValue: [backendCondition]
      }
    ]
  });
