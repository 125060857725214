import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { KitabonnementComponent } from './demandes/kitabonnement/kitabonnement.component';
import { TablevaleurComponent } from './demandes/tablevaleur/tablevaleur.component';
import { AppComponent } from './app.component';
import { AutredemandeComponent } from './demandes/autredemande/autredemande.component';
// import { LoginComponent } from './login/login.component';
import { ErrorComponent } from './error/error.component';
import { DemandesComponent } from './demandes/demandes.component';
import { AuthGuard } from './services/auth-guard.service';

import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
// TO REMOVE import { AccountLayoutComponent } from './layouts/account/account-layout.component';
import { NotAuthorizedComponent } from './layouts/NotAuthorized/not-authorized.component';




const routes: Routes = [ 
                         { path:'demandes',canActivate: [AuthGuard],  component:DemandesComponent },
                         { path:'error',  component:ErrorComponent },
                         { path:'home', component: AuthLayoutComponent,},
                         { path: 'not-authorized',component: NotAuthorizedComponent},
                         { path: '',redirectTo: 'home',pathMatch: 'full',},
//                         {
//                          path: 'app',
//                          component: AccountLayoutComponent,
//                          children: [{
//                              path: 'demandes',
//                              loadChildren: () => DemandesComponent
//                          }
//                        ],
//                          canActivate: [AuthGuard] 
//                      }
                        //  { path:'', canActivate: [AuthGuard], component:LoginComponent },
                        //  { path:'login',  component:LoginComponent },
                        // { path:'demandes/autredemande',  component:AutredemandeComponent },
                        // { path:'demandes/kitabonnement',  component:KitabonnementComponent },
                        // { path:'demandes/tablevaleur',  component:TablevaleurComponent },
                        // { path: '**', redirectTo: '', pathMatch: 'full'}
            ];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
