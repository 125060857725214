import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';

import { KitabonnementComponent } from './demandes/kitabonnement/kitabonnement.component';
import { TablevaleurComponent } from './demandes/tablevaleur/tablevaleur.component';
import { AutredemandeComponent } from './demandes/autredemande/autredemande.component';
import { DemandesComponent } from './demandes/demandes.component';
import { ErrorComponent } from './error/error.component';
import { CommonModule,APP_BASE_HREF, LocationStrategy, HashLocationStrategy } from '@angular/common';  
//import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
// import {
//   GoogleLoginProvider
//} from '@abacritt/angularx-social-login';
import { environment } from 'src/environments/environment';
import { AwsService } from './services/aws.service';
// import { UserLoginService } from './services/user-login-service';
import { UserLoginService } from './core/services/user-login.service'
import { CloudEndpointService } from './services/cloud-end-point-service';
import { AuthGuard } from './services/auth-guard.service';

import {GoogleSigninComponent,LogoutComponent} from './shared/google-signin/google-signin.component'
import { GoogleSigninBtnComponent} from './shared/google-signin/google-signin-btn.component'
import { UserAccountService } from './core/services/user-account.service';

import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
// TOREMOVE import { AccountLayoutComponent } from './layouts/account/account-layout.component';
import { NotAuthorizedComponent } from './layouts/NotAuthorized/not-authorized.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatSidenavModule} from '@angular/material/sidenav'
// Shared components
// TOREMOVE import { NavbarModule} from './shared/navbar/navbar.module';
// TOREMOVE import { SidebarModule } from './shared/sidebar/sidebar.module';
// TOREMOVE import { FooterModule } from './shared/footer/footer.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatTreeModule} from '@angular/material/tree';




@NgModule({
  declarations: [
    AppComponent,
    GoogleSigninComponent,
    GoogleSigninBtnComponent,
    AuthLayoutComponent,
 // TOREMOVE  AccountLayoutComponent,
    NotAuthorizedComponent,
    LogoutComponent,
    DemandesComponent,
    KitabonnementComponent,
    TablevaleurComponent,
    AutredemandeComponent,
    ErrorComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CommonModule,
    FlexLayoutModule,
// TOREMOVE     NavbarModule,
//    SidebarModule,
    MatSidenavModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatCardModule,
    MatSelectModule,
    MatFormFieldModule,
    MatTreeModule,
  //  SocialLoginModule
  
  ],
  providers: [
    UserLoginService,
    UserAccountService,
    { provide: APP_BASE_HREF, useValue: '/' },
  { provide: LocationStrategy, useClass: HashLocationStrategy },
  //   {
  //   provide: 'SocialAuthServiceConfig',
  //   useValue: {
  //     autoLogin: false,
  //     providers: [
  //       {
  //         id: GoogleLoginProvider.PROVIDER_ID,
  //         provider: new GoogleLoginProvider(
  //           environment.googleClientId
  //         )
  //       }
  //     ]
  //   } as SocialAuthServiceConfig,
  // },
   AwsService,CloudEndpointService],
  bootstrap: [AppComponent]
})
export class AppModule { }
