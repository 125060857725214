import {Injectable, signal} from '@angular/core';

export interface Toast {
  message: string;
  type: 'error' | 'warning' | 'info' | 'success';
  id: number;
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private toasts = signal<Toast[]>([]);
  private counter = 0;

  show(message: string, type: 'error' | 'warning' | 'info' | 'success' = 'error', duration: number = 3000) {
    const id = this.counter++;
    const toast: Toast = { message, type, id };

    this.toasts.update(current => [...current, toast]);

    setTimeout(() => {
      this.toasts.update(current => current.filter(t => t.id !== id));
    }, duration);
  }

  toasts$ = this.toasts.asReadonly();

}
