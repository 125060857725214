import {Component, inject, model, signal} from '@angular/core';
import {catchError, finalize, switchMap} from 'rxjs/operators';
import {CommonModule} from '@angular/common';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {GaelService} from '../../gael.service';
import {GaelFichierWSDTO, Item} from '../../model';
import {CodeLibelleWsdto} from '../../../parametrages/model';
import {ParametrageService} from '../../../parametrages/parametrages.service';
import {NgIcon, provideIcons} from '@ng-icons/core';
import {heroCheckCircle, heroExclamationCircle} from '@ng-icons/heroicons/outline';


@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  imports: [CommonModule,FormsModule,ReactiveFormsModule, NgIcon],
  providers: [provideIcons({heroCheckCircle, heroExclamationCircle})]
})
export class FileUploadComponent {

  private gaelService = inject(GaelService)
  private parametrageService = inject(ParametrageService);

  isDragging = signal(false);
  isUploading = signal(false);
  uploadStatus = signal<'success' | 'error' | null>(null);

  fileToUpdate = model<GaelFichierWSDTO | null>(null);
  item = model<Item | null>(null);
  codeLibelles = signal<CodeLibelleWsdto[]>([]);
  showUploadForm = signal(false);
  showFileTypeError = signal(false)

  fileUploadForm: FormGroup;

  constructor() {
    this.fileUploadForm = new FormGroup({
      fileType: new FormControl('', Validators.required),
    });
  }

  ngOnInit() {
    this.fetchCodeLibelles();
  }

  fetchCodeLibelles() {
    this.parametrageService.getCodeLibelleByTable('GAELTYDOC').pipe().subscribe({
      next: (data:CodeLibelleWsdto[]) => {
        this.codeLibelles.set(data);
        this.fileUploadForm.get('fileType')?.setValue(this.fileToUpdate()?.gfi_num_type);
      },
      error: (error) => {
        console.error('Erreur lors de la récupération des codes libellés:', error);
      }
    });
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    this.isDragging.set(true);
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    this.isDragging.set(false);
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    this.isDragging.set(false);
    const files = event.dataTransfer?.files;
    if (files && files.length > 0) {
      this.uploadFile(files[0]);
    }
  }

  onChangeSelect(event: any) {
    this.showUploadForm.set(true)
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.uploadFile(input.files[0]);
    }
  }

  uploadFile(file: File) {
    if (this.fileUploadForm.invalid || file.type != "application/pdf") {
      this.fileUploadForm.markAllAsTouched();
      this.showFileTypeError.set(true);
      return;
    }
    this.isUploading.set(true);
    this.uploadStatus.set(null);

    //recupere une url s3 presigned
    this.gaelService.getUploadPresignedUrl().pipe(
      switchMap(response => {
        // nom du fichier
        const key = response.fields.key.split('/').pop();
        //upload le fichier dans le s3
        return this.gaelService.uploadFile(file, response).pipe(
          switchMap(() => {
            // createFile ou updateFile lorsque uploadFile dans le s3
            const item = this.item();
            const fileType = this.fileUploadForm.get('fileType')?.value;
            if (item && key) {
              if(this.fileToUpdate() != null)
                {
                  return this.gaelService.updateFile(this.fileToUpdate()!, key);
                }
                else{
                  return this.gaelService.createFile(item, key, fileType);
                }
            } else {
              throw new Error('Item is null or key is null');
            }
          })
        );
      }),
      catchError(error => {
        console.error('Erreur lors du téléchargement:', error);
        // Au lieu de retourner of(null), on lance une nouvelle erreur
        throw error;
      }),
      finalize(() => {
        this.isUploading.set(false);
      })
    ).subscribe({
      next: (response) => {
          if(this.fileToUpdate() == null)
          {
            this.item.update((item) => {
              if (item) {
                item.fichiers.push(response);
              }
              return item;
            });
          }else{
          this.fileToUpdate.update((uploadFile) => {
            if (uploadFile) {
              uploadFile.gfi_nom = response.gfi_nom;
              uploadFile.gfi_num_type = response.gfi_num_type;
              uploadFile.gfi_credate = response.gfi_credate;
              uploadFile.gfi_moddate = response.gfi_moddate;
              uploadFile.gfi_metas = response.gfi_metas;
            }
            return uploadFile;
          })
        }

        this.uploadStatus.set('success');
      },
      error: (error) => {
        console.error('Erreur capturée dans subscribe:', error);
        this.uploadStatus.set('error');
        // Ici, vous pouvez ajouter une logique pour afficher un message d'erreur spécifique
        if (error.status === 403) {
          // Gérer l'erreur 403 Forbidden
          console.log('Erreur 403: Accès refusé');
        } else if (error.status === 400) {
          // Gérer l'erreur 400 Bad Request
          console.log('Erreur 400: Requête incorrecte');
        } else {
          // Gérer les autres types d'erreurs
          console.log('Erreur inattendue:', error.message);
        }
      }
    });
  }
}
