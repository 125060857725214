<nav class="bg-white border-gray-200 dark:bg-blue-500">
  <div class="max-w-screen-xl flex flex-row-reverse flex-wrap mx-auto p-4 content-end text-white">
    <div>
      @if (!authenticated()) {
        <button class="action-item" (click)="login()">Se connecter</button>
      } @else {
        <button class="action-item" (click)="logout()">Se deconnecter</button>
      }
    </div>
  </div>
</nav>
