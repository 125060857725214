import {Component, EventEmitter, inject, input, model, Output, signal} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadComponent } from '../file-upload/file-upload.component';
import { GaelService } from '../../gael.service';
import { catchError, finalize, map, of, tap } from 'rxjs';
import { GaelFichierWSDTO, Item, PresignedUrlWSDTO } from '../../model';
import {NgIcon, provideIcons} from '@ng-icons/core';
import {heroXMark} from '@ng-icons/heroicons/outline';
import {FileMetadataComponent} from '../file-metadata/file-metadata.component';
import {DeleteConfirmModalComponent} from '../delete-confirm-modal/delete-confirm-modal.component';

@Component({
  selector: 'file-modal',
  templateUrl: './file-modal.component.html',
  styleUrls: ['./file-modal.component.css'],
  standalone: true,
  imports: [CommonModule, FileUploadComponent, NgIcon, FileMetadataComponent, DeleteConfirmModalComponent],
  providers: [provideIcons({heroXMark})]
})
export class FileModalComponent {

  private gaelService = inject(GaelService)

  item = model<Item | null>(null);
  isFileUploadOpen = signal(false);
  isFileMetadataModalOpen = signal(false);
  isDeleteModalOpen = signal(false);
  fileToUpdate = signal<GaelFichierWSDTO | null>(null);

  @Output() resetItemEvent = new EventEmitter<null>();

  closeModal() {
    this.item.update((item) => {
      return null;
    });
    this.resetItemEvent.emit(null);
  }

  updateMetadata(fichier: GaelFichierWSDTO){
    this.fileToUpdate.set(fichier)
    this.isFileMetadataModalOpen.set(true)
  }

  openDeleteModal(fichier: GaelFichierWSDTO) {
    this.fileToUpdate.set(fichier);
    this.isDeleteModalOpen.set(true)
  }

  updateFile(fichier: GaelFichierWSDTO)
  {
    this.fileToUpdate.set(fichier);
    this.isFileUploadOpen.set(true);
  }

  handleConfirmDeleteFile(fichier: GaelFichierWSDTO | null) : void {
    if (fichier) {
      this.deleteFile(fichier);
    }
  }

  deleteFile(fichier: GaelFichierWSDTO | null) : void
  {
    if (fichier) {
      this.gaelService.deleteFile(fichier).pipe(
        tap(() => {
          // Mettre à jour le modèle item pour supprimer le fichier
          this.item.update((item) => {
            if (item) {
              item.fichiers = item.fichiers.filter(f => f.gfi_id !== fichier.gfi_id);
            }
            return item;
          });
        }),
        catchError(error => {
          console.error('Erreur lors de la suppression du fichier:', error);
          // Gérer l'erreur
          return of(null);
        }),
        finalize(() => {
          this.isDeleteModalOpen.set(false);
        })
      ).subscribe();
    }
  }

  addFile()
  {
    this.fileToUpdate.set(null);
    this.isFileUploadOpen.set(true);
  }

  closeFileUploadModal() {
    this.fileToUpdate.set(null);
    this.isFileUploadOpen.set(false);
  }

  closeFileMetadataModal() {
    this.fileToUpdate.set(null);
    this.isFileMetadataModalOpen.set(false);
  }

  closeDeleteModal() {
    this.isDeleteModalOpen.set(false)
  }

  onValidateDeleteModal() {
    this.deleteFile(this.fileToUpdate());
    this.closeFileUploadModal()
  }

  viewFile(fichier: GaelFichierWSDTO) {
    this.gaelService.viewFile(fichier)
  }

  protected readonly console = console;
}
